@charset "utf-8";

/*
@include sp($point-sp) {}
@include tb($point-tb) {}
@include pc01($point-pc01) {}
@include pc02($point-pc02) {}
*/

.table {
  &Title {
    padding: 0 0 0 5px;
    @include sp($point-sp) {
      font-size: 14px;
      margin: 0 0 8px;
    }
    @include pc01($point-pc01) {
      font-size: 18px;
      margin: 0 0 13px;
    }
  }
  &__num {
    @include sp($point-sp) {
      line-height: 1.2;
    }
    img {
      @include sp($point-sp) {
        width: 13px;
        height: 13px;
        margin: 0 4px 0 0;
      }
      @include pc01($point-pc01) {
        width: 25px;
        height: 25px;
        margin: 0 10px 0 0;
      }
    }
  }
  &Wrap {
    &__note {
      font-weight: normal;
      @include sp($point-sp) {
        font-size: 9px;
        padding: 5px;
        line-height: 13px;
      }
      @include pc01($point-pc01) {
        font-size: 14px;
        padding: 10px 5px;
        line-height: 25px;
      }
    }
  }
}

.section__table {
  border-collapse: collapse;
  width: 100%;
  @include sp($point-sp) {
    line-height: 14px;
  }
  @include pc01($point-pc01) {
    line-height: 1.5;
  }
  td,
  th {
    vertical-align: middle;
  }
  &--typeA {
    tr {
      border-top: 1px solid;
      width: 100%;
      @include sp($point-sp) {}
      @include pc01($point-pc01) {}
      &:last-child {
        border-bottom: 1px solid;
      }
    }
    th {
      text-align: left;
      @include sp($point-sp) {
        font-size: 12px;
        width: 60px;
        max-width: 60px;
        padding: 5px 0 5px 5px;
        line-height: 1.8;
      }
      @include pc01($point-pc01) {
        font-size: 18px;
        width: 105px;
        padding: 8px 0 8px 5px;
      }
    }
    td {
      font-weight: normal;
      &.u-fwBold {
        font-weight: bold;
      }
      &.u-fwNormal {
        font-weight: bold;
      }
      @include sp($point-sp) {
        font-size: 12px;
        padding: 5px 0;
        width: calc(100% - 60px);
        max-width: 231px;
        line-height: 1.8;
      }
      @include pc01($point-pc01) {
        font-size: 14px;
        padding: 12px 0 12px 4px;
        max-width: 322px;
      }
    }
    &+.section__table--typeA {
      tr {
        &:first-child {
          @include sp($point-sp) {
            border-top: none;
          }
        }
      }
    }
    &--wide {
      th,
      td {
        @include pc01($point-pc01) {
          width: 405px;
        }
      }
    }
  }
  &--typeB {
    tr {
      border-top: 1px solid;
      &:last-child {
        border-bottom: 1px solid;
      }
    }
    th {
      text-align: left;
      border-right: 1px solid;
      font-weight: normal;
      @include sp($point-sp) {
        width: 70px;
        font-size: 12px;
        padding: 5px 8px;
        line-height: 1.8;
      }
      @include pc01($point-pc01) {
        width: 110px;
        font-size: 15px;
        padding: 16px 8px;
      }
    }
    td {
      font-weight: normal;
      text-align: left;
      @include sp($point-sp) {
        font-size: 12px;
        padding: 3px 17px 3px 23px;
        line-height: 1.8;
      }
      @include pc01($point-pc01) {
        font-size: 15px;
        padding: 16px 17px 16px 23px;
      }
      &.hasSelectBox {
        position: relative;
      }
      &.tdUpdw {
        @include sp($point-sp) {
          padding: 8px 17px 0 23px;
        }
      }
      &.optTbl {
        @include sp($point-sp) {
          padding: 3px 0 3px 23px;
        }
        @include pc01($point-pc01) {
          padding: 16px 0 16px 23px;
        }
      }
    }
  }
  &--typeC {
    tr {
      border-top: 1px solid;
      @include sp($point-sp) {}
      @include pc01($point-pc01) {}
      &:last-child {
        border-bottom: 1px solid;
      }
    }
    th {
      text-align: left;
      font-weight: normal;
      @include sp($point-sp) {
        font-size: 10px;
        padding: 8px 0 8px 5px;
        line-height: 1.8;
      }
      @include pc01($point-pc01) {
        font-size: 14px;
        padding: 10px 0 10px 5px;
        line-height: 25px;
      }
    }
    td {
      font-weight: normal;
      text-align: right;
      @include sp($point-sp) {
        font-size: 10px;
        padding: 8px 5px 8px 0;
        line-height: 13px;
      }
      @include pc01($point-pc01) {
        font-size: 14px;
        padding: 10px 5px;
        min-width: 92px;
        line-height: 25px;
      }
    }
  }
  &--typeD {
    @include pc01($point-pc01) {
      width: 100%;
      margin: 0 0 70px;
      line-height: 1;
      thead {
        th {
          border-style: none solid;
          border-width: 1px;
          border-color: #0000c8;
          position: relative;
          height: 100px;
          width: 60px;
          &:first-child {
            border-style: none;
          }
          &:nth-child(2) {
            border-style: none;
          }
          &:last-child {
            border-style: none;
          }
          span {
            -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            height: 100px;
            line-height: 1em;
            -webkit-text-orientation: upright;
            text-orientation: upright;
            text-align: left;
            font-size: 17px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            &.small {
              font-size: 15px;
            }
          }
        }
      }
      tbody {
        tr {
          th {
            padding: 12px 24px 12px 0;
          }
          td {
            // border-bottom: 1px solid #0000c8;
            border-style: solid solid;
            border-width: 1px;
            border-color: #0000c8;
            text-align: center;
            &:nth-child(2) {
              border-style: solid none;
            }
            &:last-child {
              border-style: solid none;
            }
            .circleDouble {
              width: 20px;
              height: 20px;
              display: inline-block;
              background: url(http://sorrykoubou.jp/shop-pro/img/sub/db_0000c8.svg) no-repeat;
              background-size: 100% 100%;
            }
          }
          &:first-child {
            td {
              border-top: none;
            }
          }
          &:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
      .table__btn {
        width: 144px;
        height: 50px;
        display: inline-block;
        color: #fff;
        font-size: 18px;
        border-radius: 14px;
        line-height: 50px;
        letter-spacing: 0;
        &:hover {
          text-decoration: none;
        }
        &.small {
          font-size: 12px;
        }
        &--ffd800 {
          background: #ffd800;
        }
        &--f4a000 {
          background: #f4a000;
        }
        &--6fb06b {
          background: #6fb06b;
        }
        &--d3bf9b {
          background: #d3bf9b;
        }
        &--75c4b4 {
          background: #75c4b4;
        }
        &--a8cf6a {
          background: #a8cf6a;
        }
        &--bbc3e3 {
          background: #bbc3e3;
        }
        &--f2a8c2 {
          background: #f2a8c2;
        }
      }
    }
  }
  &--typeE {
    tr {
      &:last-child {
        td {
          @include sp($point-sp) {
            border-bottom: 1px solid $main-color;
          }
        }
      }
    }
    th {
      @include sp($point-sp) {
        font-size: 12px;
        width: 60px;
        max-width: 60px;
        padding: 5px 0 5px 5px;
        text-align: left;
        vertical-align: top;
        border-bottom: 1px solid $main-color;
        line-height: 1.8;
      }
    }
    td {
      @include sp($point-sp) {
        font-size: 12px;
        font-weight: normal;
        padding: 5px 0;
        width: calc(100% - 60px);
        max-width: 231px;
        border-bottom: 1px dashed $main-color;
        line-height: 1.8;
      }
    }
  }
  .hasNum {
    position: relative;
    @include sp($point-sp) {
      padding: 8px 0 8px 20px;
    }
    @include pc01($point-pc01) {
      padding: 15px 0 15px 35px;
    }
    .table__num {
      position: absolute;
      left: 2px;
      @include sp($point-sp) {
        top: 11px;
      }
      @include pc01($point-pc01) {
        top: 15px;
      }
    }
  }
  .hasDoubleCircle {
    position: relative;
    @include sp($point-sp) {
      padding: 8px 0 8px 18px;
    }
    @include pc01($point-pc01) {
      padding: 15px 0 15px 24px;
    }
    &::before {
      content: "◎";
      width: 20px;
      height: 20px;
      position: absolute;
      left: 5px;
    }
  }
}

.c-table {
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid $main-color;
  border-right: 1px solid $main-color;
  caption {}
  thead {
    th {}
  }
  th,
  td {
    vertical-align: top;
    border-top: 1px solid $main-color;
    border-left: 1px solid $main-color;
    padding: 12px 16px;
    line-height: (18/14);
  }
  th {
    @include sp($point-sp) {
      width: 24%;
    }
    @include pc01($point-pc01) {
      width: 180px;
    }
  }
  td {}
}


//商品詳細　オプションテーブル
// #option_tbl
#option_tbl {
  width: 100%;
	.stock_head {
    border: none;
    .cell_1 {
      padding: 0 10px 0 0;
      border: none;
      @include sp($point-sp) {
        font-size: 12px;
      }
      @include pc01($point-pc01) {
        font-size: 15px;
      }
      &:last-child {
        padding: 0;
      }
    }
	}
  .cell_2 {
      padding: 0 10px 0 0;
      font-size: 12px;
      &:last-child {
        padding: 0;
      }
    div {
      display: inline-block;
      margin: 0 5px 0 0;
      input {
      }
    }
    label {
      // display: inline-block !important;
      .table_price {
      }
    }
    input[type=radio] {
      width: 14px;
      height: 14px;
      border: 1px solid #0000c8;
      background: #0000c8;
    }
  }
}
