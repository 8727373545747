@charset "utf-8";

/*
@include sp($point-sp) {}
@include tb($point-tb) {}
@include pc01($point-pc01) {}
@include pc02($point-pc02) {}
*/

.c-snsBtn {
  &__list {
    display: flex;
    @include sp($point-sp) {
      justify-content: center;
    }
    @include pc01($point-pc01) {
    }
  }
  &__item {
    margin: 0 0 0 10px;
    &:first-child {
      margin: 0;
      width: 85px;
    }
    a {
      background: #0000c8;
      color: #fff;
      display: inline;
      font-weight: bold;
      letter-spacing: 0.5px;
      font-size: 10px;
      padding: 2px 9px;
      @include sp($point-sp) {
      }
      @include pc01($point-pc01) {
        position: relative;
        bottom: 2px;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}
