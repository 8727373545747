@charset "utf-8";

/*
@include sp($point-sp) {}
@include tb($point-tb) {}
@include pc01($point-pc01) {}
@include pc02($point-pc02) {}
*/

.c-icon {
  position: relative;
  &::before {
    content: "";
    position: absolute;
  }
  &--search {
    @include sp($point-sp) {
      display: block;
      height: 30px;
    }
    @include pc01($point-pc01) {
      display: inline;
    }
    &::before {
      background: url(http://sorrykoubou.jp/shop-pro/img/common/search.svg) no-repeat;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      @include sp($point-sp) {
        width: 12px;
        height: 12px;
        right: 0;
      }
      @include pc01($point-pc01) {
        width: 24px;
        height: 24px;
        left: 0;
      }
    }
  }
}

.c-circleIcn {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 25px;
  margin-right: 7px;
  line-height: 23px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  border: 1px solid $main-color;
  border-radius: 50%;
  @include sp($point-sp) {
    width: 20px;
    height: 20px;
    line-height: 19px;
    font-size: 10px;
    text-indent: 0;
    margin-right: 5px;
  }
}
