@charset "utf-8";

/*
@include sp($point-sp) {}
@include tb($point-tb) {}
@include pc01($point-pc01) {}
@include pc02($point-pc02) {}
*/


.c-contentsTitle {
  @include sp($point-sp) {
    font-size: 18px;
    margin: 0 0 13px;
    line-height: 1.5;
  }
  @include pc01($point-pc01) {
    font-size: 30px;
    margin: 0 0 52px;
  }
  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.SPleft {
    @include sp($point-sp) {
      text-align: left;
    }
  }
  &.right {
    text-align: right;
  }
  &--sub {
    text-align: center;
    @include sp($point-sp) {
      font-size: 7px;
      margin: 0 0 4px;
      line-height: 13px;
    }
    @include pc01($point-pc01) {
      font-size: 14px;
      margin: 0 0 17px;
    }
    &.SPleft {
      @include sp($point-sp) {
        text-align: left;
      }
    }
    img {
      margin: 0 5px 0 0;
      @include sp($point-sp) {
        width: 79px;
      }
      @include pc01($point-pc01) {
        width: 158px;
      }
    }
  }
}

.c-subtitle {
  @include sp($point-sp) {
    font-size: 14px;
    margin: 0 0 13px;
    line-height: 1.4;
  }
  @include pc01($point-pc01) {
    font-size: 18px;
    margin: 0 0 40px;
  }
  &--withLine {
    position: relative;
    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background: $main-color;
      position: absolute;
      left: 0;
      z-index: -1;
      @include sp($point-sp) {
        top: 5px;
      }
      @include pc01($point-pc01) {
        top: 9px;
      }
    }
    .withLine__text {
      background: #fff;
      @include sp($point-sp) {
        padding: 0 5px;
      }
      @include pc01($point-pc01) {
        padding: 0 10px;
      }
    }
  }
  &--bgBlue {
    color: #fff;
    background: #0000c8;
    @include sp($point-sp) {
      padding: 7px 0;
    }
    @include pc01($point-pc01) {
      font-size: 21px;
      padding: 13px 0;
    }
    &--PCmore844 {
      @include pc01($point-pc01) {
        position: relative;
      }
      &::after {
        @include pc01($point-pc01) {
          content: "";
          width: 1054px;
          height: 48px;
          position: absolute;
          z-index: -1;
          background: #0000c8;
          left: -105px;
          top: 0;
        }
      }
    }
  }
  &--withUnderLine {
    border-bottom: 1px solid $main-color;
    @include sp($point-sp) {
      padding: 0 0 4px;
    }
    @include pc01($point-pc01) {
      padding: 0 0 8px;
    }
  }
  &--vertical {
    position: relative;
    @include sp($point-sp) {
      margin: 0 0 8px;
      &::after {
        content: "";
        width: 95%;
        height: 1px;
        position: absolute;
        background: $main-color;
        right: 0;
        bottom: 3px;
        z-index: -1;
      }
    }
    @include pc01($point-pc01) {
      -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      -webkit-text-orientation: upright;
      text-orientation: upright;
      font-size: 30px;
      line-height: 90px;
      top: -45px;
      left: -45px;
      margin: 0 0 -178px;
    }
    .c-subtitle {
      &__text {
        @include sp($point-sp) {
          display: inline-block;
          padding: 4px 0 0 2px;
          font-size: 12px;
        }
        @include pc01($point-pc01) {
          margin: -10px 0 0;
          letter-spacing: 3px;
        }
      }
      &__num {
        @include sp($point-sp) {
          width: 22.5px;
          height: 22.5px;
        }
        @include pc01($point-pc01) {
          width: 90px;
          height: 90px;
        }
      }
    }
  }
  &--tableType {
    @include sp($point-sp) {
      margin: 0 0 5px;
      padding: 0 0 8px;
    }
    @include pc01($point-pc01) {
      width: 48%;
      padding: 0 0 10px;
      margin: 0 0 15px;
    }
  }
  &--noDecoration {
    @include sp($point-sp) {
      font-size: 12px;
    }
    @include pc01($point-pc01) {
      font-size: 14px;
      margin: 0 0 20px;
    }
  }
  &.center {
    text-align: center;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
}
